import { useMatch } from 'react-router-dom';
import { useMedia } from 'react-use';

import {
  FEATURE_FLAGS_FOR_SELLER,
  media,
  ROLES,
} from '@savgroup-front-common/constants';
import {
  useAuthorizedCreateModalTypes,
  useHasRole,
  useIsSuperAdmin,
} from '@savgroup-front-common/core/src/hooks';
import { PRICING_PLAN_STATE } from '@savgroup-front-common/types';

import { ROUTES } from '../../../../constants/routing';
import { useGetFeatureFlagsQuery } from '../../../../hooks';
import { useAppContext } from '../../../app/App.context';
import useGetMenuItems from '../Menu/hooks/useGetMenuItems';

const MAX_ITEMS_COUNT_ON_HEADER = 9;

export const useShouldUseMinimalHeader = () => {
  const isSmallScreen = useMedia(media.maxWidth.xl);

  const isDemoAdmin = useHasRole(ROLES.DEMO_ADMINISTRATOR);
  const isSuperAdmin = useIsSuperAdmin();
  const { selectedSeller } = useAppContext();
  const isConfigurationConsolePage = !!useMatch(ROUTES.CONFIGURATION_CONSOLE);
  const canSwitchToNewBo = useGetFeatureFlagsQuery({
    featureFlag: FEATURE_FLAGS_FOR_SELLER.SWITCH_NEW_BO,
  });

  const { menus } = useGetMenuItems();

  const shouldDisplayUpgradeButton =
    isDemoAdmin ||
    isSuperAdmin ||
    selectedSeller?.pricingPlanState === PRICING_PLAN_STATE.CHURN;

  const shouldShowSwitchNewBo = canSwitchToNewBo && !isConfigurationConsolePage;

  const authorizations = useAuthorizedCreateModalTypes();

  const totalItemsCountOnHeader =
    menus.length +
    (shouldDisplayUpgradeButton ? 1 : 0) +
    (shouldShowSwitchNewBo ? 1 : 0) +
    (authorizations.length > 0 ? 1 : 0);

  if (isSmallScreen) {
    return totalItemsCountOnHeader >= MAX_ITEMS_COUNT_ON_HEADER;
  }

  return false;
};
