import React, { FC } from 'react';

import {
  BUTTON_TYPES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import Button from '@savgroup-front-common/core/src/atoms/button/Button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { PlanDto } from '../../../ChoosePlan.types';

import { useConfirmPushToProductionModalContent } from './ConfirmPushToProductionModalContent.hooks';
import { $ActionRow } from './ConfirmPushToProductionModalContent.styles';
import messages from './messages';

interface ConfirmPushToProductionModalContentProps {
  onClose: () => void;
  plan: PlanDto;
}

export const ConfirmPushToProductionModalContent: FC<
  ConfirmPushToProductionModalContentProps
> = ({ onClose, plan }) => {
  const { handlePushToProduction, isLoading } =
    useConfirmPushToProductionModalContent({ plan });

  return (
    <>
      <Heading level={3}>
        <SafeFormattedMessageWithoutSpread
          message={messages.youWillExitDemoContext}
        />
      </Heading>

      <Banner notificationType={NOTIFICATION_TYPES.INFO} isFluid>
        <SafeFormattedMessageWithoutSpread
          message={messages.youWillExitDemoContextDescription}
        />
      </Banner>

      <$ActionRow>
        <Button
          type={BUTTON_TYPES.BUTTON}
          onClick={onClose}
          isLoading={isLoading}
          danger
          naked
        >
          <SafeFormattedMessageWithoutSpread message={messages.stayInDemo} />
        </Button>

        <Button
          primary
          type={BUTTON_TYPES.BUTTON}
          onClick={() => handlePushToProduction()}
          isLoading={isLoading}
        >
          <SafeFormattedMessageWithoutSpread message={messages.confirm} />
        </Button>
      </$ActionRow>
    </>
  );
};
