import toInteger from 'lodash/toInteger';

export const updateHeight = (
  valueCount: number,
  element?: HTMLTextAreaElement,
  autoScroll?: boolean,
) => {
  if (element) {
    const { maxHeight, minHeight, borderBottomWidth, borderTopWidth } =
      window.getComputedStyle(element);
    const borderHeight =
      parseFloat(borderBottomWidth) + parseFloat(borderTopWidth);

    const rect = element.getBoundingClientRect();
    const firstRowMaxCounter = rect.width / 9;

    if (valueCount < firstRowMaxCounter && valueCount > 0) {
      return;
    }

    element.style.height = 'auto';
    element.style.overflowY = 'hidden';

    const { value } = element as HTMLTextAreaElement;

    const targetHeight = value
      ? Math.max(parseFloat(minHeight), element.scrollHeight + borderHeight)
      : Math.min(parseFloat(minHeight), element.scrollHeight + borderHeight);

    if (targetHeight > parseFloat(maxHeight)) {
      element.style.overflowY = '';
    }

    // update the height
    element.style.height = `${toInteger(targetHeight)}px`;
    // scroll to cursor
    if (autoScroll) {
      element.blur();
      element.focus();
    }
  }
};

export function checkScrollBarActivationNeeded({
  height,
  maxHeight,
  value,
  valueCount,
}: {
  valueCount: number;
  height: string;
  maxHeight: string;
  value?: string;
}) {
  return (
    (valueCount > 0 &&
      Number(height.replace('px', '')) >=
        Number(maxHeight.replace('px', ''))) ||
    (value?.match(/\n/g) || []).length > 0
  );
}
