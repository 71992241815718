import { FunctionComponent } from 'react';

import { MessageType } from '@savgroup-front-common/types';

export enum MENU_NAMES {
  ALERTS = 'ALERTS',
  TODOS = 'TODOS',
  FILES = 'FILES',
  FILES_HISTORIC = 'FILES_HISTORIC',
  SPARE_PARTS = 'SPARE_PARTS',
  SPARE_PART_REQUESTS = 'SPARE_PART_REQUESTS',
  SPARE_PART_REQUESTS_TO_VALIDATE = 'SPARE_PART_REQUESTS_TO_VALIDATE',
  SPARE_PART_STOCK = 'SPARE_PART_STOCK',
  CREATE_SPARE_PART_REQUEST = 'CREATE_SPARE_PART_REQUEST',
  SPARE_PART_INVENTORIES = 'SPARE_PART_INVENTORIES',
  MESSAGES = 'MESSAGES',
  ORDERS = 'ORDERS',
  BUSINESS_INTELLIGENCE = 'BUSINESS_INTELLIGENCE',
  SETTINGS = 'SETTINGS',
  SETTINGS_SELLERS = 'SETTINGS_SELLERS',
  SETTINGS_MASTER = 'SETTINGS_MASTER',
  SETTINGS_USERS = 'SETTINGS_USERS',
  SETTINGS_TESTS = 'SETTINGS_TESTS',
  SETTINGS_IMPORTS = 'SETTINGS_IMPORTS',
  SETTINGS_SPARE_PARTS = 'SETTINGS_SPARE_PARTS',
  SETTINGS_SPARE_PART_CATALOG = 'SETTINGS_SPARE_PART_CATALOG',
  BULK_CHANGES = 'BULK_CHANGES',
  CONFIGURATION_CONSOLE = 'CONFIGURATION_CONSOLE',
  CONFIGURATION_CONSOLE_RULES = 'CONFIGURATION_CONSOLE_RULES',
  CONFIGURATION_CONSOLE_OPTIONS = 'CONFIGURATION_CONSOLE_OPTIONS',
  CONFIGURATION_CONSOLE_IMPORT = 'CONFIGURATION_CONSOLE_IMPORT',
  BILLINGS = 'BILLINGS',
  BILLINGS_TRACKING = 'BILLINGS_TRACKING',
  BILLING_CUSTOMERS_LIST = 'BILLING_CUSTOMERS_LIST',
  BILLING_PARAMETERS = 'BILLING_PARAMETERS',
}

export interface MenuItem {
  to: string;
  path: string;
  dataTestId: string;
  message: MessageType;
  icon: FunctionComponent<
    React.PropsWithChildren<{ color: string; size: string }>
  >;
  count: number;
  subCount: number;
  expandIfAlone: boolean;
  isExternal?: boolean;
  submenus: MenuItem[];
}
export interface MenuConf {
  nav: MENU_NAMES;
  count?: number;
  permission?: boolean;
  forceIconIfAlone?: boolean;
  expandIfAlone?: boolean;
  isExternal?: boolean;
  submenus?: MenuConf[];
}

export enum MENU_ORIENTATION {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}
