import React, { FC } from 'react';
import { Link, useMatch } from 'react-router-dom';

import {
  FEATURE_FLAGS_FOR_SELLER,
  POSITIONS,
  ROLES,
} from '@savgroup-front-common/constants';
import {
  useHasRole,
  useIsSuperAdmin,
} from '@savgroup-front-common/core/src/hooks';
import { ReversioIcon } from '@savgroup-front-common/core/src/protons/icons';
import { PRICING_PLAN_STATE } from '@savgroup-front-common/types';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';

import { ROUTES } from '../../../../constants/routing';
import { useGetFeatureFlagsQuery } from '../../../../hooks';
import { useAppContext } from '../../../app/App.context';
import { SearchInputVariant } from '../../../atoms/SearchInput/SearchInput.types';
import SearchWithDimmer from '../../../molecules/SearchWithDimmer/SearchWithDimmer';
import { ResultSearchType } from '../../../molecules/SearchWithDimmer/SearchWithDimmer.types';
import CreateButton from '../CreateButton/CreateButton';
import { useHeaderNewBo } from '../Header.hooks';
import {
  $AppHeaderContainer,
  $HeaderLogo,
  $HeaderPortal,
  $HeaderSearchBar,
  $NavigationMenuWrapper,
  $SellerSelectPortal,
} from '../Header.styles';
import { useShouldUseMinimalHeader } from '../hooks/useShouldUseMinimalHeader';
import Menu from '../Menu/Menu';
import messages from '../messages';
import Profile from '../Profile/HeaderProfile';
import { SwitchNewBo } from '../SwitchNewBo/SwitchNewBo';
import { UpgradePlanButton } from '../UpgradePlanButton/UpgradePlanButton';

import { MinimalHeaderNewUi } from './MinimalHeaderNewUi/MinimalHeaderNewUi';

export const HeaderNewUi: FC = () => {
  const isConfigurationConsolePage = !!useMatch(ROUTES.CONFIGURATION_CONSOLE);
  const canSwitchToNewBo = useGetFeatureFlagsQuery({
    featureFlag: FEATURE_FLAGS_FOR_SELLER.SWITCH_NEW_BO,
  });
  const shouldUseMinimalHeader = useShouldUseMinimalHeader();

  const isDemoAdmin = useHasRole(ROLES.DEMO_ADMINISTRATOR);
  const isSuperAdmin = useIsSuperAdmin();
  const { selectedSeller } = useAppContext();
  const isFromScratch = useMatch(ROUTES.FROM_SCRATCH);

  const {
    isLoading,
    searchResult,
    query,
    handleChangeSearchQuery,
    handleShowMore,
  } = useHeaderNewBo();

  const shouldDisplayUpgradeButton =
    isDemoAdmin ||
    isSuperAdmin ||
    selectedSeller?.pricingPlanState === PRICING_PLAN_STATE.CHURN;

  const shouldShowSwitchNewBo = canSwitchToNewBo && !isConfigurationConsolePage;

  if (shouldUseMinimalHeader) {
    return <MinimalHeaderNewUi />;
  }

  return (
    <$AppHeaderContainer $isNewUiEnabled>
      <$HeaderLogo>
        <Link to={ROUTES.HOME}>
          <ReversioIcon color="#fff" />
        </Link>
      </$HeaderLogo>
      {!isFromScratch && (
        <$NavigationMenuWrapper>
          <Menu />
        </$NavigationMenuWrapper>
      )}
      {!isConfigurationConsolePage && !isFromScratch && (
        <>
          {shouldShowSwitchNewBo && <SwitchNewBo />}
          {shouldDisplayUpgradeButton && <UpgradePlanButton />}

          <CreateButton />

          <$HeaderSearchBar>
            <SearchWithDimmer
              isLoading={isLoading}
              query={query}
              placeholder={messages.searchPlaceholder}
              results={
                (searchResult as SearchResultList<ResultSearchType>[]) || []
              }
              onSearch={handleChangeSearchQuery}
              onShowMore={handleShowMore}
              minWidthResult={488}
              position={POSITIONS.RIGHT}
              topResult={48}
              withoutMarginBottom
              isHightContrast
              variant={SearchInputVariant.GLOBAL_SEARCH_NEW_UI}
            />
          </$HeaderSearchBar>
        </>
      )}
      {isConfigurationConsolePage && <$HeaderPortal id="headerPortalId" />}
      {isConfigurationConsolePage && (
        <$SellerSelectPortal id="headerSellerSelectPortalId" />
      )}
      <Profile />
    </$AppHeaderContainer>
  );
};
