import { useLayoutEffect } from 'react';

import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { useSearchParams } from '@savgroup-front-common/core/src/hooks';
import { NEW_SETTINGS_ROUTES } from 'control/view/pages/NewSettings/NewSettingsPage.routes';

import { useAppContext } from '../App.context';

const ShippingBoRedirectRoutes = ({
  isFromScratchSeller,
}: {
  isFromScratchSeller?: boolean;
}) => {
  const { selectedSeller } = useAppContext();
  const searchParams = useSearchParams();
  const code = searchParams.get('code') ?? undefined;

  useLayoutEffect(() => {
    if (!isFromScratchSeller && selectedSeller) {
      window.location.href = buildUrl(
        NEW_SETTINGS_ROUTES.CONFIGURATION_CONSOLE_CATALOG,
        {
          tenantId: selectedSeller.tenantId,
          sellerId: selectedSeller.id,
          code,
        },
      );
    }
  }, [code, isFromScratchSeller, selectedSeller]);
};

export default ShippingBoRedirectRoutes;
