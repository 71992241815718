import 'react-router-dom';
import { generatePath } from 'react-router-dom';

import { buildUrl } from '@savgroup-front-common/core/src/helpers';

import { BASES } from '../../../constants/routing';

import messages from './messages';

export enum NEW_SETTINGS_PAGES {
  ACCOUNT = 'account',
  CONNECTED_APPS = 'connectedApps',
  THEME = 'theme',
  PROFILES = 'profiles',
  USERS = 'users',
  CONFIGURATION_CONSOLE = 'configurationConsole',
  BILLING = 'billing',
  SUBSCRIPTION = 'subscription',
  WARRANTIES = 'warranties',
  CATALOG = 'catalog',
  WAREHOUSES = 'warehouses',
  REPAIRERS = 'repairers',
  CARRIERS = 'carriers',
  STORES = 'stores',
  SUPPLIERS = 'suppliers',
  RULES = 'rules',
}

export const NEW_SETTINGS_ROUTES = {
  DEFAULT: `${BASES.SETTINGS}`,
  ACCOUNT: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.ACCOUNT}`,
  CONNECTED_APPS: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.CONNECTED_APPS}`,
  THEME: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.THEME}`,
  USERS: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.USERS}`,
  PROFILES: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.USERS}/${NEW_SETTINGS_PAGES.PROFILES}`,
  CONFIGURATION_CONSOLE: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.CONFIGURATION_CONSOLE}`,
  CONFIGURATION_CONSOLE_WARRANTIES: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.CONFIGURATION_CONSOLE}/${NEW_SETTINGS_PAGES.WARRANTIES}`,
  CONFIGURATION_CONSOLE_CATALOG: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.CONFIGURATION_CONSOLE}/${NEW_SETTINGS_PAGES.CATALOG}`,
  CONFIGURATION_CONSOLE_WAREHOUSES: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.CONFIGURATION_CONSOLE}/${NEW_SETTINGS_PAGES.WAREHOUSES}`,
  BILLING: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.BILLING}`,
  SUBSCRIPTION: `${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.SUBSCRIPTION}`,
};

export const SETTINGS_RELATIVE_ROUTES = {
  ACCOUNT: `${NEW_SETTINGS_PAGES.ACCOUNT}`,
  CONNECTED_APPS: `${NEW_SETTINGS_PAGES.CONNECTED_APPS}`,
  THEME: `${NEW_SETTINGS_PAGES.THEME}`,
  USERS: `${NEW_SETTINGS_PAGES.USERS}`,
  PROFILES: `${NEW_SETTINGS_PAGES.PROFILES}`,
  CONFIGURATION_CONSOLE: `${NEW_SETTINGS_PAGES.CONFIGURATION_CONSOLE}`,
  CONFIGURATION_CONSOLE_WARRANTIES: `${NEW_SETTINGS_PAGES.CONFIGURATION_CONSOLE}/${NEW_SETTINGS_PAGES.WARRANTIES}`,
  CONFIGURATION_CONSOLE_CATALOG: `${NEW_SETTINGS_PAGES.CONFIGURATION_CONSOLE}/${NEW_SETTINGS_PAGES.CATALOG}`,
  CONFIGURATION_CONSOLE_WAREHOUSES: `${NEW_SETTINGS_PAGES.CONFIGURATION_CONSOLE}/${NEW_SETTINGS_PAGES.WAREHOUSES}`,
  BILLING: `${NEW_SETTINGS_PAGES.BILLING}`,
  SUBSCRIPTION: `${NEW_SETTINGS_PAGES.SUBSCRIPTION}`,
};

export const settingsRoutes: any = {
  [NEW_SETTINGS_PAGES.ACCOUNT]: {
    name: NEW_SETTINGS_PAGES.ACCOUNT,
    path: NEW_SETTINGS_ROUTES.ACCOUNT,
    exact: false,
    message: messages.account,
  },
  [NEW_SETTINGS_PAGES.USERS]: {
    name: NEW_SETTINGS_PAGES.USERS,
    path: NEW_SETTINGS_ROUTES.USERS,
    exact: false,
    message: messages.userManagement,
  },
  [NEW_SETTINGS_PAGES.CONNECTED_APPS]: {
    name: NEW_SETTINGS_PAGES.CONNECTED_APPS,
    path: NEW_SETTINGS_ROUTES.CONNECTED_APPS,
    exact: false,
    message: messages.connectedApps,
  },
  [NEW_SETTINGS_PAGES.THEME]: {
    name: NEW_SETTINGS_PAGES.THEME,
    path: NEW_SETTINGS_ROUTES.THEME,
    exact: false,
    message: messages.theme,
  },
  [NEW_SETTINGS_PAGES.CONFIGURATION_CONSOLE]: {
    name: NEW_SETTINGS_PAGES.CONFIGURATION_CONSOLE,
    path: NEW_SETTINGS_ROUTES.CONFIGURATION_CONSOLE,
    exact: false,
    message: messages.configurationConsole,
  },
  [NEW_SETTINGS_PAGES.BILLING]: {
    name: NEW_SETTINGS_PAGES.BILLING,
    path: NEW_SETTINGS_ROUTES.BILLING,
    exact: false,
    message: messages.billing,
  },
  [NEW_SETTINGS_PAGES.SUBSCRIPTION]: {
    name: NEW_SETTINGS_PAGES.SUBSCRIPTION,
    path: NEW_SETTINGS_ROUTES.SUBSCRIPTION,
    exact: false,
    message: messages.subscription,
  },
};

export const buildSubscriptionUrl = ({
  tenantId,
  sellerId,
}: {
  tenantId: string;
  sellerId: string;
}) => {
  return buildUrl(
    generatePath(`${BASES.SETTINGS}/${NEW_SETTINGS_PAGES.SUBSCRIPTION}`),
    {
      tenantId,
      sellerId,
    },
  );
};
