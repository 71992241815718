import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  BUTTON_TYPES,
  FEATURE_FLAGS_FOR_SELLER,
  POSITIONS,
  ROLES,
} from '@savgroup-front-common/constants';
import { SwipeableDrawer } from '@savgroup-front-common/core/src/atoms/SwipeableDrawer';
import {
  DIRECTION,
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import {
  useHasRole,
  useIsSuperAdmin,
} from '@savgroup-front-common/core/src/hooks';
import {
  MenuHamburgerCloseIcon,
  ReversioIcon,
} from '@savgroup-front-common/core/src/protons/icons';
import { PRICING_PLAN_STATE } from '@savgroup-front-common/types';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';

import { ROUTES } from '../../../../../constants/routing';
import { useGetFeatureFlagsQuery } from '../../../../../hooks';
import { useAppContext } from '../../../../app/App.context';
import { SearchInputVariant } from '../../../../atoms/SearchInput/SearchInput.types';
import SearchWithDimmer from '../../../../molecules/SearchWithDimmer/SearchWithDimmer';
import { ResultSearchType } from '../../../../molecules/SearchWithDimmer/SearchWithDimmer.types';
import CreateButton from '../../CreateButton/CreateButton';
import { useHeaderNewBo } from '../../Header.hooks';
import {
  $AppHeaderContainer,
  $HeaderLogo,
  $HeaderPortal,
  $HeaderSearchBar,
  $SellerSelectPortal,
} from '../../Header.styles';
import messages from '../../messages';
import Profile from '../../Profile/HeaderProfile';
import { SwitchNewBo } from '../../SwitchNewBo/SwitchNewBo';
import { UpgradePlanButton } from '../../UpgradePlanButton/UpgradePlanButton';

import {
  $Header,
  $MenuHamburger,
  $RightHeaderContainer,
} from './MinimalHeaderNewUi.styles';
import { MinimalHeaderSidebar } from './MinimalHeaderSidebar/MinimalHeaderSidebar';

export const MinimalHeaderNewUi: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();
  const iconColor = theme.colors.white;
  const location = useLocation();

  const isConfigurationConsolePage = !!useMatch(ROUTES.CONFIGURATION_CONSOLE);
  const canSwitchToNewBo = useGetFeatureFlagsQuery({
    featureFlag: FEATURE_FLAGS_FOR_SELLER.SWITCH_NEW_BO,
  });
  const isDemoAdmin = useHasRole(ROLES.DEMO_ADMINISTRATOR);
  const isSuperAdmin = useIsSuperAdmin();
  const { selectedSeller } = useAppContext();
  const isFromScratch = useMatch(ROUTES.FROM_SCRATCH);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const {
    isLoading,
    searchResult,
    query,
    handleChangeSearchQuery,
    handleShowMore,
  } = useHeaderNewBo();

  return (
    <$AppHeaderContainer $isNewUiEnabled>
      <$Header>
        <$MenuHamburger
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          type={BUTTON_TYPES.BUTTON}
        >
          <MenuHamburgerCloseIcon color={iconColor} />
        </$MenuHamburger>

        <$HeaderLogo>
          <Link to={ROUTES.HOME}>
            <ReversioIcon color="#fff" />
          </Link>
        </$HeaderLogo>

        <SwipeableDrawer
          hasCloseButton={false}
          hasPadding={false}
          isOpen={isMenuOpen}
          direction={DIRECTION.RIGHT}
          size={DRAWER_SIZE.X_SMALL}
          onClose={() => setIsMenuOpen(false)}
        >
          <MinimalHeaderSidebar onClose={() => setIsMenuOpen(false)} />
        </SwipeableDrawer>
      </$Header>

      <$RightHeaderContainer>
        {!isConfigurationConsolePage && !isFromScratch && (
          <>
            {canSwitchToNewBo && !isConfigurationConsolePage && <SwitchNewBo />}
            {(isDemoAdmin ||
              isSuperAdmin ||
              selectedSeller?.pricingPlanState ===
                PRICING_PLAN_STATE.CHURN) && <UpgradePlanButton />}

            <CreateButton />

            <$HeaderSearchBar>
              <SearchWithDimmer
                isLoading={isLoading}
                query={query}
                placeholder={messages.searchPlaceholder}
                results={
                  (searchResult as SearchResultList<ResultSearchType>[]) || []
                }
                onSearch={handleChangeSearchQuery}
                onShowMore={handleShowMore}
                minWidthResult={488}
                position={POSITIONS.RIGHT}
                topResult={48}
                withoutMarginBottom
                isHightContrast
                variant={SearchInputVariant.GLOBAL_SEARCH_NEW_UI}
              />
            </$HeaderSearchBar>
          </>
        )}
        {isConfigurationConsolePage && <$HeaderPortal id="headerPortalId" />}
        {isConfigurationConsolePage && (
          <$SellerSelectPortal id="headerSellerSelectPortalId" />
        )}
        <Profile />
      </$RightHeaderContainer>
    </$AppHeaderContainer>
  );
};
